export const LogLevel = Object.freeze({
    INFO: 'info',
    DEBUG: 'debug',
    LOG: 'log',
    WARNING: 'warning',
    ERROR: 'error',
    FATAL: 'fatal',
});

export const sendSentryMessage = (message, loglevel = LogLevel.INFO) => {
    if (window.Sentry) {
        Sentry.withScope(() => {
            Sentry.captureMessage(message, loglevel);
        });
    }
};
