import { getSessionId } from './session';
import { getSnowplowPageViewData, getOriginParameters, trackSnowplowEvent } from './snowplow';
import { setReferrerUrl } from '@snowplow/browser-tracker';

const client_id = window.__ES_CLIENT_ID;
const page_context = window.__ES_CONTEXT;
const TRACKING_CONTEXT_COOKIE_KEY = 'KC_CONTEXT_DATA';

function addPageContext(context) {
    return {
        schema: 'iglu:com.epidemicsound/context/jsonschema/1-0-31',
        data: {
            context: context,
        },
    };
}

function addSessionContext() {
    return {
        schema: 'iglu:com.epidemicsound/web_session/jsonschema/2-0-0',
        data: {
            client_session_id: getSessionId(),
        },
    };
}

function addKeycloakContext(ssoAlias) {
    const identityProvider = ssoAlias ? { identity_provider: ssoAlias } : {};
    return {
        schema: 'iglu:com.epidemicsound/keycloak_user/jsonschema/1-0-4',
        data: {
            client_id: client_id,
            user_id: '',
            ...identityProvider,
        },
    };
}

function trackButtonClick(action, context) {
    trackSnowplowEvent('cta_clicked', {
        event: {
            schema: 'iglu:com.epidemicsound/cta_clicked/jsonschema/3-0-0',
            data: {
                action: action,
            },
        },
        // array of custom contexts
        context: [addPageContext(context), addSessionContext(), addKeycloakContext()],
    });
}

export function trackFlagDecision(flagKey, variationKey, ruleKey, enabled) {
    trackSnowplowEvent('flag_decision', {
        event: {
            schema: 'iglu:com.epidemicsound/flag_decision/jsonschema/1-0-0',
            data: {
                flag_key: flagKey,
                variation_key: variationKey,
                rule_key: ruleKey,
                enabled: enabled,
            },
        },
        context: [addPageContext('OPTIMIZELY'), addSessionContext(), addKeycloakContext()],
    });
}

function trackPageView(context) {
    let contexts = [addSessionContext(), addKeycloakContext()];
    if (context) {
        contexts.push(addPageContext(context));
    }
    trackSnowplowEvent('pageview', {
        event: {
            schema: 'iglu:com.epidemicsound/pageview/jsonschema/4-0-2',
            data: {
                ...getSnowplowPageViewData(),
            },
        },
        context: contexts,
    });
}

function handleCTAClick(event, button) {
    const action = `${button.getAttribute(window.__ES_SNOWPLOW_DATA_CTA_CLICKED_ACTION_KEY)}-clicked`;
    const context = `${button.getAttribute(window.__ES_SNOWPLOW_PAGE_CONTEXT_KEY)}`;
    trackButtonClick(action, context);
}

function handleAnchorOriginParameters() {
    const anchors = document.querySelectorAll('a');
    anchors.forEach(function (anchor) {
        // Add origin_parameters to all internal links
        if (anchor.hostname === window.location.hostname && !anchor.href.includes('social-')) {
            let newUrl = new URL(anchor.href);
            let currentUrl = new URL(window.location.href);
            currentUrl.searchParams.delete('origin_parameters');
            const originParameters = getOriginParameters();

            const newOriginParameters = {
                ...originParameters,
                referrer: currentUrl.toString(),
            };
            newUrl.searchParams.set(
                'origin_parameters',
                safeBase64Encode(JSON.stringify(newOriginParameters)),
            );
            anchor.href = newUrl.toString();
        }
    });
}

function safeBase64Encode(str) {
    const utf8Bytes = new TextEncoder().encode(str);
    const binaryString = Array.from(utf8Bytes)
        .map((byte) => String.fromCharCode(byte))
        .join('');
    return btoa(binaryString);
}

function addClickListeners() {
    const authlinks = document.querySelectorAll(`[${window.__ES_SNOWPLOW_DATA_CTA_CLICKED_ACTION_KEY}]`);
    authlinks.forEach(function (link) {
        // Add CTA click events for links
        link.addEventListener('click', function (event) {
            handleCTAClick(event, this);
        });
    });

    const registerLinks = document.querySelectorAll('[data-signup-funnel-eligible]');
    registerLinks.forEach(function (link) {
        link.addEventListener('click', function (event) {
            trackUserCreationButtonClick(event, this);
        });
    });
}

function addTrackingContextCookie() {
    const contextData = JSON.stringify({
        // Here we can add additional context data we want to include in the events
        client_session_id: getSessionId(),
        useragent: navigator.userAgent,
    });

    document.cookie = `${TRACKING_CONTEXT_COOKIE_KEY}=${btoa(contextData)};path=/;max-age=63072000`;
}

function addLogoReferrer() {
    const logo = document.getElementById('es-logo-link');
    if (logo) {
        logo.href += `?override_referrer=${window.location.origin + window.location.pathname}`;
    }
}

function addSignupContentPlacementContext(contentPlacement) {
    return {
        schema: 'iglu:com.epidemicsound/signup_content_placement/jsonschema/1-0-1',
        data: {
            signup_content_placement: contentPlacement,
        },
    };
}

function setPageReferrer() {
    const referrer = getSnowplowPageViewData()?.referrer;
    if (referrer) {
        // Setting referrer url as snowplow field page_referrer does not work out of the box
        // because document.referrer is cleaned up by Keycloak
        setReferrerUrl(referrer);
    }
}

function trackUserCreationButtonClick(event, button) {
    const context = `${button.getAttribute(window.__ES_SNOWPLOW_PAGE_CONTEXT_KEY)}`;

    if (context !== 'SIGNUP_PAGE') {
        return;
    }
    setPageReferrer();

    const ssoAlias = button.getAttribute('data-sso-alias') || '';
    trackSnowplowEvent('user_creation_confirmation_button_pressed', {
        event: {
            schema: 'iglu:com.epidemicsound/user_creation_confirmation_button_pressed/jsonschema/1-0-0',
            data: {},
        },
        context: [
            addSessionContext(),
            addKeycloakContext(ssoAlias),
            addSignupContentPlacementContext('auth_page'),
        ],
    });
}

function trackUserCreationFormViewed(context) {
    if (context !== 'SIGNUP_PAGE') {
        return;
    }
    setPageReferrer();

    trackSnowplowEvent('user_creation_form_viewed', {
        event: {
            schema: 'iglu:com.epidemicsound/user_creation_form_viewed/jsonschema/1-0-0',
            data: {},
        },
        context: [addSessionContext(), addKeycloakContext(), addSignupContentPlacementContext('auth_page')],
    });
}

export const initEventListener = () => {
    document.addEventListener('DOMContentLoaded', () => {
        addLogoReferrer();
        addClickListeners();
        handleAnchorOriginParameters();
        addTrackingContextCookie();
        trackPageView(page_context);
        trackUserCreationFormViewed(page_context);
    });
};
